<template>
  <div :class="'main-header ' + ($store.state.fleet.currentOrganization !== $store.state.user.currentUser.organizationLabel ? 'orgwarning': '')">
    
    <div @click="sideBarToggle" style="width: 100px, height: 30px">
      <div v-if="$store.state.user.currentUser && $store.state.user.currentUser.role > 0"  class="menu-toggle" id="isamenu_toggle">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    
    


    <div style="margin: auto"></div>
    <div class="logo header-part-center" >
      <img :src="IMGService.isalogonotxt" class="logo " alt v-if="$store.state.fleet.sseState"  v-touch:longtap="hardRefresh"/>
      <img :src="IMGService.isalogonotxtdisconnected" class="logo" alt v-if="!$store.state.fleet.sseState"  v-touch:longtap="hardRefresh"/>
      <span v-if="!$store.state.fleet.sseState" class="disctext">{{$t('Disconnected')}}</span>
      <span v-if="$store.state.fleet.currentOrganization !== $store.state.user.currentUser.organizationLabel " class="imptext">{{$store.state.fleet.currentOrganization}}</span>
    </div>   
    <div style="margin: auto"></div>

    <div class="header-part-right">      
      <div v-if="$route.path === '/app/kiosk'" style="margin-right: 10px">
      <font-awesome-icon size="2x" icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISAKioskHelp')"/>
      </div>      
      <ISAKioskSessionDropdown />
      <ISALanguageSelector/>
      <ISASessionDropdown/>     
      <ISAChangePasswordModal />
      <ISARoomBedExitSettingsModal/>  
      <ISAAbout/>
    </div>

  </div>

  <!-- header top menu end -->
</template>
<script>
import Util from "@/utils";
import Sidebar from "./Sidebar";
import { mapGetters, mapActions } from "vuex";
import { mixin as clickaway } from "vue-clickaway";
import ISASessionDropdown from '@/components/ISA/session/ISASessionDropdown';
import ISAKioskSessionDropdown from '@/components/ISA/session/ISAKioskSessionDropdown';
import ISAAbout from '@/components/ISA/session/ISAAbout';
import OrganizationSelector from '@/components/ISA/session/OrganizationSelector';
import ISALanguageSelector from '@/components/ISA/session/ISALanguageSelector';
import ISAChangePasswordModal from '@/components/ISA/session/ISAChangePasswordModal'
import ISARoomBedExitSettingsModal from '@/components/ISA/kiosk/ISARoomBedExitSettingsModal'




import { IMGService } from '@/services/img.service'

export default {
  mixins: [clickaway],
  components: {
    Sidebar,
    ISALanguageSelector,    
    ISASessionDropdown,
    OrganizationSelector,
    ISAKioskSessionDropdown,
    ISAChangePasswordModal,
    ISARoomBedExitSettingsModal,
    ISAAbout,
  },

  data() {
    return {
      IMGService,
      isDisplay: true,
      isStyle: true,
      isSearchOpen: false,
      isMouseOnMegaMenu: true,
      isMegaMenuOpen: false
    };
  },
  watch: {
    "$store.state.user.currentUser.role": function(val) {
      if(val === 0)
        this.$store.commit("closeSidebarProperties");
    },
  },
  mounted() {
    // document.addEventListener("click", this.closeMegaMenu);
    
  },
  computed: {
    ...mapGetters(["getSideBarToggleProperties"]),    
  },

  methods: {
    
    ...mapActions([
      "changeSecondarySidebarProperties",

      "changeSidebarProperties",
      "changeThemeMode",
      "signOut"
    ]),

    // megaMenuToggle() {
    //   this.isMegaMenuOpen = false;

    //   console.log("work");
    // },
    hardRefresh() {
      this.$store.dispatch("user/showAbout", true);       
    },
    handleFullScreen() {
      Util.toggleFullScreen();
    },
    logoutUser() {
      this.signOut();

      this.$router.push("/app/sessions/signIn");
    },

    closeMegaMenu() {
      this.isMegaMenuOpen = false;
      // console.log(this.isMouseOnMegaMenu);
      // if (!this.isMouseOnMegaMenu) {
      //   this.isMegaMenuOpen = !this.isMegaMenuOpen;
      // }
    },
    toggleMegaMenu() {
      this.isMegaMenuOpen = !this.isMegaMenuOpen;
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },

    sideBarToggle(el) {
      if (
        this.getSideBarToggleProperties.isSideNavOpen &&
        this.getSideBarToggleProperties.isSecondarySideNavOpen &&
        this.$store.state.user.isMobile
      ) {
        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
      } else if (
        this.getSideBarToggleProperties.isSideNavOpen &&
        this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        this.changeSecondarySidebarProperties();
      } else if (this.getSideBarToggleProperties.isSideNavOpen) {
        this.changeSidebarProperties();
      } else if (
        !this.getSideBarToggleProperties.isSideNavOpen &&
        !this.getSideBarToggleProperties.isSecondarySideNavOpen &&
        !this.getSideBarToggleProperties.isActiveSecondarySideNav
      ) {
        this.changeSidebarProperties();
      } else if (
        !this.getSideBarToggleProperties.isSideNavOpen &&
        !this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        // console.log("4");

        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
        // console.log("4");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.logo {
  cursor: pointer;
  width: 100%;
  object-fit: contain;
}
.disctext {
  color: red;
  margin-left: 1rem;
}

.imptext {
  color: rgb(98, 0, 255);  
  width: 3000px;
}

.centerlogo {
  margin: 0 auto;
  display: block;
  position: absolute;
  left: calc((100% - 120px) / 2);
  z-index: 100000;
}
.layout-sidebar-large .main-header .logo img {
    width: 100%;    
}

</style> 

