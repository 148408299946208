<template>      
      <div class="dropdown">
        <b-dropdown
          v-if="$store.state.user.currentUser.oid && $store.state.user.currentUser.role !== 0"
          id="dropdown-1"
          text="Dropdown Button"
          class="m-md-2 user col align-self-end"
          toggle-class="text-decoration-none"
          no-caret
          variant="link"
        >
          <template slot="button-content">
            <avatar :size="25" :username="(($store.state.user.currentUser.firstName && $store.state.user.currentUser.lastName) ? ($store.state.user.currentUser.firstName + ' ' + $store.state.user.currentUser.lastName) :  $store.state.user.currentUser.username )"></avatar>
          </template>

         

          <div class="dropdown-menu-right" aria-labelledby="userDropdown">
            <div v-if="isLogged" class="dropdown-header">
              <i class="i-Lock-User mr-1"></i> {{ $t('Logged as') + ' ' + (($store.state.user.currentUser.firstname && $store.state.user.currentUser.lastname) ? ($store.state.user.currentUser.firstname + ' ' + $store.state.user.currentUser.lastname) :  $store.state.user.currentUser.username ) }}
            </div>
            <OrganizationSelector />
            <a class="dropdown-item" v-if="isLogged && $store.state.user.kioskUser.oid" href="#" @click.prevent="changePattern">{{ $t('Reset lock pattern') }}</a>
            <a class="dropdown-item" v-if="isLogged" href="#" @click.prevent="changePassword">{{ $t('Change password') }}</a>
            <a class="dropdown-item" v-if="isLogged" href="#" @click.prevent="logout" id="dropdown_logout">{{ $t('Sign out')  + ' ' + (($store.state.user.currentUser.firstname && $store.state.user.currentUser.lastname) ? ($store.state.user.currentUser.firstname + ' ' + $store.state.user.currentUser.lastname) :  $store.state.user.currentUser.username )}}</a>
            <a class="dropdown-item" v-if="showLogin" href="#" @click.prevent="login">{{ $t('Sign in') }}</a>
        <!--
            <a class="dropdown-item" v-if="pushnotificationSupported" href="#" @click.prevent="onesignalprompt">{{ $t('Push notification supported') }}</a>
            <a class="dropdown-item" v-if="!pushnotificationSupported" href="#" @click.prevent="onesignalprompt">{{ $t('Push notification not supported') }}</a>
            <a class="dropdown-item" v-if="onesignaledRegistered" href="#" @click.prevent="onesignalTagging">{{ $t('Push notification enabled') }}</a>
            <a class="dropdown-item" v-if="!onesignaledRegistered" href="#" @click.prevent="onesignalprompt">{{ $t('Push notification not enabled') }}</a>
        -->
        <div class="separator border-top"></div>
            <div  class="dropdown-footer d-flex">
              <img :src="IMGService.logo" />
              <a class="dropdown-item" href="#" @click.prevent="about">{{ $t('About...' )  }}</a>
            </div>
            
          </div>

          
        </b-dropdown>        
        <a class="dropdown-item" v-else href="#" @click.prevent="login">{{ $t('Sign in') }}</a>
        
        
        
      </div>  
</template>

<script>
import { AuthService } from '@/services/auth.service'
import { SPService } from '@/services/sp.service'
import Avatar from 'vue-avatar'
import OrganizationSelector from './OrganizationSelector'
import ISAKioskSessionDropdown from './ISAKioskSessionDropdown'
import { VERSIONDATE } from '@/version'
import { IMGService } from '@/services/img.service'

export default {
  name: 'Header',
  components: {
    Avatar,
    OrganizationSelector,
    ISAKioskSessionDropdown,
  },
  methods: {
    async onesignalprompt() {
      window.OneSignal.push(function() {
        window.OneSignal.registerForPushNotifications();
      });
    },
    onesignalTagging() {
      const self = this;
      const oid = self.$store.state.user.currentUser.oid
      const org = self.$store.state.fleet.currentOrganization
      /*window.OneSignal.push(function() {
        window.OneSignal.setEmail(self.$store.state.user.currentUser.email)          
          .then(function(emailId) {
            // Callback called when email have finished sending
            console.log("emailId: ", emailId);  
          }); 
      });*/
      
      window.OneSignal.push(function() {
        window.OneSignal.setExternalUserId(oid);
      });
      
      window.OneSignal.push(function() {
      window.OneSignal.sendTags({        
        organization: org,
      }).then(function(tagsSent) {
        // Callback called when tags have finished sending    
        self.onesignalTagged = true;        
      });
    });
    },
    async logout () {
      await AuthService.makeLogout()
      if(!this.$store.state.user.kioskUser.oid)
        this.$router.push('/app/sessions/signIn')
      else
        this.$router.push('/app/kiosk')
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'menu_logout',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
    },
    async login () {
      // we need to start a modal for login
      this.$store.dispatch('user/needsLogin',true);
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'menu_login',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
    },
    hideContent () {
      if (this.isShown) this.isShown = false
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown () {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown () {
      this.activeNotifications = false
    },
    toggleSidebar () {      
        
        this.$store.commit('user/SET_KIOSKMODE',!this.$store.state.user.kioskMode)
        SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'menu_set_kiosk',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
    },
    hideSidebar () {
      this.$sidebar.displaySidebar(false)
    },
    changePattern() {
       this.$store.commit('user/SET_NEEDS_CHANGEPATTERN',true)
       SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'menu_change_pattern',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
    },
    changePassword() {
       this.$store.commit('user/SET_NEEDS_CHANGEPASSWORD',true)
       SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'menu_change_password',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
    }, 
    about() {
      this.$store.dispatch('user/showAbout', true);
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'menu_show_about',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
    }
  },
  data() {
    return {
        onesignaledRegistered: false,
        onesignalTagged: false,
        VERSIONDATE,
        IMGService
    }
  },  
  computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      },
      isLogged() {
          return (this.$store.state.user.currentUser.username && this.$store.state.user.currentUser.role > 0)
      },
      showLogin() {        
        return (!this.$store.state.user.currentUser.username || this.$store.state.user.currentUser.role === 0)  && this.$router.currentRoute.path.indexOf('authorize') < 0                
      },
      iconChar() {
        if(this.$store.state.user.currentUser.username && this.$store.state.user.currentUser.role > 0)
          return '&#x1F464;'
        else return '&#128274;'
      },
      navclass() {
        if(!this.$store.state.user.kioskMode && !this.$store.state.user.kioskPage)
          return "administration"
        else return "kiosk"
      },
      pushnotificationSupported() {
        window.OneSignal.push(function() {
        /* These examples are all valid */
       //var isPushSupported = window.OneSignal.isPushNotificationsSupported();
       // debugger
      //  if (isPushSupported) {
          // Push notifications are supported
       //   return true;
       // } else {
          // Push notifications are not supported
          return false;
      //  }
      });
      },
      
  },  
  mounted() {    
        const self = this;
       /* if(window.OneSignal)
          window.OneSignal.isPushNotificationsEnabled().then((isEnabled) => {
          if (isEnabled)
            self.onesignaledRegistered = true;
          else
            self.onesignaledRegistered = false;
      });      */
      
  }

}
</script>

<style lang="scss" scoped>
.menu-text {
    color: #ffffff;
    text-align: center;
    font-weight: 400;
    line-height: 30px;
  }

.header {
  z-index: 1;
  padding: 20px 0;
  position: relative;

  .is-active {
    color: #e01b3c;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  

  .menu {
    font-size: 25px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      padding: 15px;

    }

    &.side {
      font-size: 15px;

      .logout-button {
        cursor: pointer;
      }
    }
  }
}
</style>
