<template>
  <div
    class="side-content-wrap"
    @mouseenter="isMenuOver = true"
    @mouseleave="isMenuOver = false"
    @touchstart="isMenuOver = true"
  >
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      :class="{ open: getSideBarToggleProperties.isSideNavOpen }"
      ref="myData"
      class="sidebar-left rtl-ps-none ps scroll"
    >
      <div>
        <ul class="navigation-left menu">

           <li                        
            class="nav-item"
            data-item="welcome"                          
            @click="$router.push('/app/welcome')"
            :class="{ active: selectedManuItem == '/app/welcome' }"
            @mouseover="selectMenuItem('/app/welcome')"
          >
              <a  class="nav-item-hold">              
                <i class="nav-icon i-Home1" id="isamenu_home"     ></i>
                <span class="nav-text">{{ $t("Welcome") }}</span>
              </a>            
            <div class="isatriangle" v-if="$route.path == '/app/welcome'"></div>
            <div class="triangle" v-else style="border-color: transparent transparent transparent transparent;"></div>
          </li>

              


          <li                        
            class="nav-item"
            data-item="reporting"
            :data-submenu="false"
            @click="$router.push('/app/reporting')"
            :class="{ active: selectedManuItem == '/app/reporting' }"
            @mouseover="selectMenuItem('/app/reporting')"
          >
            <a  class="nav-item-hold">    
              <i class="nav-icon i-Bar-Chart" id="isamenu_dashboard"></i>
              <span class="nav-text">{{ $t("Insights") }}</span>
            </a>     

            <div class="isatriangle" v-if="$route.path == '/app/reporting'"></div>
          </li>

          

          <li            
            class="nav-item"           
            :class="{ active: selectedManuItem == '/app/usersmanagement' }"
            @mouseover="selectMenuItem('/app/usersmanagement')"
            data-item="users"
            :data-submenu="false"
            @click="$router.push('/app/usersmanagement')"
            
          >
            <a class="nav-item-hold" >
              <i class="nav-icon i-Male" id="isamenu_usermanagement"></i>
              <span class="nav-text">{{ $t("Users") }}</span>
            </a>     
            <div class="isatriangle" v-if="$route.path == '/app/usersmanagement'"></div>
          </li>

          <li            
            class="nav-item"           
            data-item="notifications"
            :data-submenu="false"
            @click="$router.push('/app/notificationsmanagement')"
            :class="{ active: selectedManuItem == '/app/notificationsmanagement' }"
            @mouseover="selectMenuItem('/app/notificationsmanagement')"
          >
            <a class="nav-item-hold" >
                <i class="nav-icon i-Bell" id="isamenu_notifications">
                  <b-icon v-if="$store.state.fleet.phoneAudit && $store.state.fleet.phoneAudit.fallErrors > 0" icon="exclamation-circle-fill" variant="danger" style="vertical-align: -20px;margin-left: 40px;margin-bottom: 15px; font-size: 25px !important;"></b-icon>                            
                </i>
                
              <span class="nav-text">{{ $t("Phones") }}</span>
              
            </a>     
            <div class="isatriangle" v-if="$route.path == '/app/notificationsmanagement'"></div>
             
          </li>

          <li
            @click="$router.push('/app/roommanagement')"            
            class="nav-item"
            :class="{ active: selectedManuItem == '/app/roommanagement' }"
            @mouseover="selectMenuItem('/app/roommanagement')"
            data-item="rooms"
            :data-submenu="false"
          >
            <a class="nav-item-hold" >
              <i class="nav-icon i-Hospital" id="isamenu_rooms"></i>
              <span class="nav-text">{{ $t("Care Units & Sensors") }}</span>
            </a>     
            <div class="isatriangle" v-if="$route.path == '/app/roommanagement'"></div>
          </li>



          <li            
            @click="$router.push('/app/userguide')"
            class="nav-item"            
            data-item="start"
            :data-submenu="false"
            :class="'last-child ' + { active: selectedManuItem == '/app/userguide' }"
            @mouseover="selectMenuItem('/app/userguide')"            
          >
            <a class="nav-item-hold" >
              <i class="nav-icon i-Life-Safer" id="isamenu_userguide"></i>
              <span class="nav-text">{{ $t("User Guide") }}</span>
            </a>     
            <div class="isatriangle" v-if="$route.path == '/app/userguide'"></div>
          </li>

                    <li            
            @click="$router.push('/app/kiosk')"
            class="nav-item"            
            data-item="start"
            :data-submenu="false"
            :class="{ active: selectedManuItem == '/app/kiosk' }"
            style="background-color: #88cabe3f;"
            @mouseover="selectMenuItem('/app/kiosk')"
          >
            <a class="nav-item-hold" >
              <i class="nav-icon i-Start" id="isamenu_kiosk"></i>
              <span class="nav-text">{{ $t("Kiosk Mode") }}</span>
            </a>     
            <div class="isatriangle" v-if="$route.path == '/app/kiosk'"></div>
          </li>

          <!--
          <li
            @mouseenter="toggleSubMenu"
            class="nav-item"
            :class="{ active: selectedParentMenu == 'ui-kits' }"
            data-item="ui-kits"
            :data-submenu="true"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-Library"></i>
              <span class="nav-text">UI kits</span>
            </a>
            <div class="triangle"></div>
          </li>
          <li
            @mouseenter="toggleSubMenu"
            class="nav-item"
            :class="{ active: selectedParentMenu == 'extrakits' }"
            data-item="extrakits"
            :data-submenu="true"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-Suitcase"></i>
              <span class="nav-text">Extra kits</span>
            </a>
            <div class="triangle"></div>
          </li>
          <li
            @mouseenter="toggleSubMenu"
            class="nav-item"
            :class="{ active: selectedParentMenu == 'apps' }"
            data-item="apps"
            :data-submenu="true"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-Computer-Secure"></i>
              <span class="nav-text">Apps</span>
            </a>
            <div class="triangle"></div>
          </li>
          <li
            @mouseenter="toggleSubMenu"
            class="nav-item"
            :class="{ active: selectedParentMenu == 'charts' }"
            data-item="charts"
            :data-submenu="true"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-Pie-Chart-2"></i>
              <span class="nav-text">Charts</span>
            </a>
            <div class="triangle"></div>
          </li>
          <li
            @mouseenter="toggleSubMenu"
            class="nav-item"
            :class="{ active: selectedParentMenu == 'widgets' }"
            data-item="widgets"
            :data-submenu="true"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-Windows-2"></i>
              <span class="nav-text">Widgets</span>
            </a>
            <div class="triangle"></div>
          </li>
          <li
            @mouseenter="toggleSubMenu"
            class="nav-item"
            :class="{ active: selectedParentMenu == 'forms' }"
            data-item="forms"
            :data-submenu="true"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-File-Clipboard-File--Text"></i>
              <span class="nav-text">Forms</span>
            </a>
            <div class="triangle"></div>
          </li>
          <li
            class="nav-item"
            @mouseenter="toggleSubMenu"
            :class="{ active: selectedParentMenu == 'datatables' }"
            data-item="datatables"
            :data-submenu="true"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-File-Horizontal-Text"></i>
              <span class="nav-text">Datatables</span>
            </a>
            <div class="triangle"></div>
          </li>
          <li
            @mouseenter="toggleSubMenu"
            class="nav-item"
            :class="{ active: selectedParentMenu == 'sessions' }"
            data-item="sessions"
            :data-submenu="true"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-Administrator"></i>
              <span class="nav-text">Sessions</span>
            </a>
            <div class="triangle"></div>
          </li>
          <li
            @mouseenter="toggleSubMenu"
            :class="{ active: selectedParentMenu == 'pages' }"
            class="nav-item"
            data-item="pages"
            :data-submenu="true"
          >
            <a class="nav-item-hold" href="#">
              <i class="nav-icon i-Double-Tap"></i>
              <span class="nav-text">Pages</span>
            </a>
            <div class="triangle"></div>
          </li>
          <li
            @mouseenter="toggleSubMenu"
            class="nav-item"
            data-item="doc"
            :class="{ active: selectedParentMenu == 'doc' }"
            :data-submenu="false"
          >
            <a
              class="nav-item-hold"
              href="http://demos.ui-lib.com/gull-vue-doc/"
              target="_blank"
            >
              <i class="nav-icon i-Safe-Box1"></i>
              <span class="nav-text">Doc</span>
            </a>
            <div class="triangle"></div>
          </li>
          -->
        </ul>
      </div>
    </vue-perfect-scrollbar>

    <div
      @click="removeOverlay()"
      class="sidebar-overlay"
      :class="{ open: getSideBarToggleProperties.isSecondarySideNavOpen }"
    ></div>
  </div>
  <!--=============== Left side End ================-->
</template>

<script>
import Topnav from "./TopNav";
import { mapGetters, mapActions } from "vuex";
import Vue from 'vue';
import { BootstrapVue, BIcon, BIconExclamationCircleFill } from 'bootstrap-vue'


Vue.component('BIconExclamationCircleFill', BIconExclamationCircleFill)
Vue.component('BIcon', BIcon)
BIcon

export default {
  components: {
    Topnav,
    BIconExclamationCircleFill,
    BIcon
  },

  data() {
    return {
      isDisplay: true,
      isMenuOver: false,
      isStyle: true,
      selectedParentMenu: "",   
      selectedManuItem: '',
    };
  },
  mounted() {
    this.toggleSelectedParentMenu();
    window.addEventListener("resize", this.handleWindowResize);
    document.addEventListener("click", this.returnSelectedParentMenu);
    this.handleWindowResize();
  },

  beforeDestroy() {
    document.removeEventListener("click", this.returnSelectedParentMenu);
    window.removeEventListener("resize", this.handleWindowResize);
  },
  computed: {
    ...mapGetters(["getSideBarToggleProperties"])
  },

  methods: {
    ...mapActions([
      "changeSecondarySidebarProperties",
      "changeSecondarySidebarPropertiesViaMenuItem",
      "changeSecondarySidebarPropertiesViaOverlay",
      "changeSidebarProperties"
    ]),

    handleWindowResize() {
      //  console.log('not working is Mobile');
      if (window.innerWidth <= 1200) {
        if (this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
        if (this.getSideBarToggleProperties.isSecondarySideNavOpen) {
          this.changeSecondarySidebarProperties();
        }
      } else {
        if (!this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
      }
    },
    toggleSelectedParentMenu() {
      const currentParentUrl = this.$route.path
        .split("/")
        .filter(x => x !== "")[1];

      if(!currentParentUrl)
      {
        this.$router.push('/app/welcome');
      }      
    },
    selectMenuItem(e) {      
        this.selectedManuItem = e;  
        this.$store.state.user.isMobile
        
        if(this.$store.state.user.isandroid || this.$store.state.user.isamobile) {
            this.$router.push(e);
        }                       
    },   
    removeOverlay() {cd 
      this.changeSecondarySidebarPropertiesViaOverlay();
      if (window.innerWidth <= 1200) {
        this.changeSidebarProperties();
      }
      this.toggleSelectedParentMenu();
    },
    returnSelectedParentMenu() {
      if (!this.isMenuOver) {
        this.toggleSelectedParentMenu();
      }
    },
    toggleSidebarDropdwon(event) {
      let dropdownMenus = this.$el.querySelectorAll(".dropdown-sidemenu.open");

      event.currentTarget.classList.toggle("open");

      dropdownMenus.forEach(dropdown => {
        dropdown.classList.remove("open");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.isatriangle {
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    bottom: 0;
    border-style: solid;
    border-width: 0 0 30px 30px;
    border-color: transparent transparent #88cabe transparent;
}

.menu {
  display: flex;
  flex-direction: column;
  list-style: none;
  text-align: center;
  height: 100%;
  margin: 20px 0 0 0;
  padding: 0;
}

.menu li:last-child {
  margin-bottom: 0px;  
  background-color: #fcd1ef25;
}

</style>
