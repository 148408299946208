<template>
   <div :class="'app-admin-wrap  layout-sidebar-large clearfix ' + ($store.state.fleet.currentOrganization !== $store.state.user.currentUser.organizationLabel ? 'orgwarning': '')">
    <top-nav @click="$store.dispatch('closeSidebar')"/>

    <sidebar />
    
    <main >
      <div
        :class="{ 'sidenav-open': getSideBarToggleProperties.isSideNavOpen }"
        class="main-content-wrap  d-flex flex-column flex-grow-1">
        <div class="container-fluid" @click="$store.dispatch('closeSidebar')">
        <transition name="page" mode="out-in">                    
            <router-view />          
        </transition>
        </div>
        <div  @click="$store.dispatch('closeSidebar')" class="flex-grow-1"></div>
        <appFooter @click="$store.dispatch('closeSidebar')"/>
      </div>
    </main>
  
  
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import TopNav from "./TopNav";
import appFooter from "../common/footer";
import { mapGetters, mapActions } from "vuex";
import { use } from "vue"
import { IMGService } from '@/services/img.service'

export default {
  components: {
    Sidebar,
    TopNav,
    appFooter,    
  },
  data() {
    return {
      welcomeText: '',
      bgImage: IMGService.bgImage,
      IMGService,
    };
  },
  computed: {
    ...mapGetters(["getSideBarToggleProperties"])
  },
  methods: {
timer() {
      const releaseDate = new Date('2021-10-06 07:00:00');
                  
      
      const diff = new Date(releaseDate) - new Date();
      

      const tM = (30 * 24 * 60 * 60 * 1000);      
      const tD = ( 1 * 24 * 60 * 60 * 1000);
      const tH = (1 * 1 * 60 * 60 * 1000);
      const tm = (1 * 1 * 1 * 60 * 1000);
      const ts = (1 * 1 * 1 * 1 * 1000);      

      let diffM = Math.floor((diff) / tM); if(diffM < 0) diffM = 0;
      let diffD = Math.floor((diff-diffM*tM) / tD);  if(diffD < 0) diffD = 0;     
      let diffH = Math.floor((diff-diffM*tM - diffD*tD) / tH);  if(diffH < 0) diffH = 0;
      let diffm = Math.floor((diff-diffM*tM - diffD*tD - diffH*tH) / tm);  if(diffm < 0) diffm = 0;
      let diffS = Math.floor((diff-diffM*tM - diffD*tD - diffH*tH - diffm*tm) / ts);  if(diffS < 0) diffS = 0;

      
      const sinceLastFall =   (diffM > 0? `${diffM} ${this.$t('months')} ` : '')
                          +  (diffD > 0? `${diffD} ${this.$t('days')} ` : '')
                          +  (diffH > 0? `${diffH} ${this.$t('hours')} ` : '')
                          +  (diffm > 0? `${diffm} ${this.$t('minutes')} ` : '')
                          +  (diffS >= 0? `${diffS} ${this.$t('seconds')}` : '');

      this.welcomeText = sinceLastFall;
            
    }
  },
  mounted() {
   // debugger
   this.timer()
   const releaseDate = new Date('2021-10-06 07:00:00');
   if(new Date() - new Date(releaseDate) <= 0) {
    setInterval(this.timer, 1000);
   }

  }
};
</script>
<style>
.orgwarning {
  background: orangered !important;
  background-color: orangered !important;
}

.isalaunch {   
  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;
  
  /* Background image doesn't tile */
  background-repeat: no-repeat;
  
  /* Background image is fixed in the viewport so that it doesn't move when 
     the content's height is greater than the image's height */
  background-attachment: fixed;
  
  /* This is what makes the background image rescale based
     on the container's size */
  background-size: cover;
  
  /* Set a background color that will be displayed
     while the background image is loading */
  background-color: #464646;

  height: 100%;

  margin-top: 0px !important; 
}

    .brand_logo {
        position: absolute;
        text-align: center;
        height: 55px;
    }

</style>