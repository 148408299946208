<template>
  
    <b-modal
      hide-footer
      v-model="showChangePasswordModal"
      title="Change your password"
      @hidden="close">  

    <div class="d-flex justify-content-center">      
      <div class="d-flex justify-content-center">
        <ISALanguageSelector style="top: 0; right: 2rem;position: absolute"/>
        <img :src="IMGService.isalogo" class="brand_logo" alt="Logo" style="margin-top: 20px" />
        <div class="form_container">
            <form style="margin-top: 20px" autocomplete="off">
                <div class="user-thumb text-center m-b-30">                     
                    <img :src="IMGService.isalock"
                         class="rounded-circle img-thumbnail"
                         alt="thumbnail"
                         style="height: 80px; padding-top:-10px"
                         v-b-toggle.collapseAdvanced />
                </div>
                <div >
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.prevent="handleSubmit(applyChangePassword)" autocomplete="off">
                            
                            <validation-provider name="CurrentPassword"
                                                 :rules="{ required: true, required: true, min: 5, max: 250 }"
                                                 v-slot="validationContext">
                                <b-form-group :label="$t('Please enter your current password here')" class="text-12">
                                    <b-form-input class="form-control-rounded password"
                                                  type="text"
                                                  autocomplete="off" readonly 
                                                  @focus="removeReadonly"   
                                                  v-model="currentPassword"                                                  
                                                  aria-describedby="input-1-live-feedback"
                                                  :state="getValidationState(validationContext)"></b-form-input>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider name="NewPassword"
                                                 :rules="{ required: true, required: true, min: 5, max: 250 }"
                                                 v-slot="validationContext">
                                <b-form-group :label="$t('Please enter your new password here')" class="text-12">
                                    <b-form-input class="form-control-rounded password"
                                                  type="text"
                                                  autocomplete="off" readonly 
                                                  @focus="removeReadonly"   
                                                  v-model="newPassword1"                                                                                                                                 
                                                  aria-describedby="input-1-live-feedback"
                                                  :state="getValidationState(validationContext)"></b-form-input>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider :name="$t('New Password Confirmation')"
                                                 rules="required|password:@NewPassword"
                                                 v-slot="validationContext">
                                <b-form-group :label="$t('Please confirm your new password')" class="text-12">
                                    <b-form-input class="form-control-rounded password"
                                                  type="text"
                                                  autocomplete="off" readonly 
                                                  @focus="removeReadonly"  
                                                  v-model="newPassword2"                                                                                                                              
                                                  aria-describedby="input-2-live-feedback"
                                                  :state="getValidationState(validationContext)"></b-form-input>
                                    <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <div class="input-group mb-3" style="margin-top: -7px; width: 100%">
                                <b-button type="submit"
                                          tag="button"
                                          class="btn-rounded btn-block mt-2"
                                           @click="changePassword"
                                          variant="primary mt-2">
                                    {{ $t('Change Password') }}
                                </b-button>
                            </div>

                        </b-form>
                    </validation-observer>
                </div>
        </form>
      </div>
    </div>
  </div>
  
    </b-modal>  
</template>




<script>
import { AuthService } from '@/services/auth.service';
import { API_URL } from '@/.env';
import { IMGService } from '@/services/img.service';
import ISALanguageSelector from './ISALanguageSelector';

export default  {
  components: {
      ISALanguageSelector
  },  
  data() {
    return {
      showChangePasswordModal: false  ,    
      currentPassword: null,
      newPassword1: null,
      newPassword2: null,
      requesting: false,    
      IMGService,  
    }
  },
  watch : {
    '$store.state.user.needsChangePassword': function(val) {
      
      this.showChangePasswordModal = val
     
    }
  },    
  methods: {
        removeReadonly(e) {
                e.target.removeAttribute('readonly');                
            },
        close() {                     
          this.$store.commit('user/SET_NEEDS_CHANGEPASSWORD',false)
        }    ,
        changeOnEnter() {

        },
        async changePassword() {
            if(this.newPassword1 !== this.newPassword2)
            {
              this.$store.commit('toast/NEW',{ type: 'error', message: 'Passwords does not match'})
              return;
            }
            try
            {              
             // debugger
              let res = await AuthService.changePassword(API_URL.url, this.$store.state.user.currentUser.jwt, this.currentPassword, this.newPassword1)
              if(res && res.status === 200 && res.data) {
                  this.$bvToast.toast(this.$t('You password has been updated'), {
                                title: this.$t('Update password'),
                                solid: true,
                                variant: 'success',
                                duration: 5000
                            });

                  this.$store.commit('user/SET_NEEDS_CHANGEPASSWORD',false);
              }
            } catch(err) {
                this.$bvToast.toast(this.$t( err.status +': ' + err.message), {
                                title: this.$t('Update password error'),
                                solid: true,
                                variant: 'warning',
                                duration: 5000
                            });              
            }            
        },
        getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
    },
    mounted() {
        this.currentPassword = '';
        this.newPassword1 = '';
        this.newPassword2 = '';
      this.$store.commit('user/SET_NEEDS_CHANGEPASSWORD',false)
    },
  name: "ISALoginModal"
}
</script>

<style lang="scss" scoped>
    .isa-primary {
        background-color: #36958d !important;
    }

    .user_card {
        height: 400px;
        width: 100%;
        margin-top: 50px;
        margin-bottom: auto;
        background: white;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .brand_logo {
        position: absolute;
        text-align: center;
        height: 55px;
    }

    .form_container {
        margin-top: 100px;
    }

    .login_btn {
        width: 100%;
        background: #36958d !important;
        color: white !important;
    }

        .login_btn:focus {
            box-shadow: none !important;
            outline: 0px !important;
        }

    .login_container {
        padding: 0 2rem;
    }

    .input-group-text {
        background: #36958d !important;
        color: white !important;
        border: 0 !important;
        border-radius: 0.25rem 0 0 0.25rem !important;
    }

    .input_user,
    .input_pass:focus {
        box-shadow: none !important;
        outline: 0px !important;
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #c0392b !important;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #36958d;
        opacity: 0.6; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #36958d;
        opacity: 0.6; /* Firefox */
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #36958d;
        opacity: 0.6; /* Firefox */
    }

    .btn:focus {
        outline: none !important;
    }

    .btn-secondary {
        border-color: white;
    }

    .nav-pills {
        background-color: white;
    }

    .text-12 {
        font-weight: bold;
        color: rgb(0, 0, 0);
    }

    .text-muted {
        font-weight: bold;
        color: rgb(87, 80, 80) !important;
    }

    .signinbtn {
        color: rgb(0, 0, 0);
        font-weight: bold;
    }

    @media screen and (max-width: 640px) {
        .text-muted {
            color: rgb(129, 122, 122) !important;
        }
    }

    .password {
        -webkit-text-security:disc;
        font-family: text-security-disc;
    }
</style>
