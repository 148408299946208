<template>      
      <div class="dropdown" v-if="$route.path == '/app/kiosk'">
        <b-dropdown          
          id="dropdown-1"
          text="Dropdown Button"
          class="m-md-2 user col align-self-end"
          toggle-class="text-decoration-none"
          no-caret
          variant="link"          
        >



          <template slot="button-content">
            <img
              v-if="$store.state.fleet.sseState"
              src="@/assets/images/kioskLogged.png"
              id="userDropdown"
              alt
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style="max-height: 5rem;"
            />
            <img
              v-if="!$store.state.fleet.sseState"
              src="@/assets/images/kioskDisconnected.png"
              id="userDropdown"
              alt
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style="max-height: 5rem;"
            />
            
          </template>
        
          <div class="dropdown-menu-right" aria-labelledby="userDropdown">     
            <span v-if="$store.state.user.kioskUser.oid" class="dropdown-item" >{{'logged as ' + $store.state.user.kioskUser.username}}</span>
            
            <a id="setup_as_new_kiosk_btn" v-if="$store.state.user.kioskable && ($store.state.user.currentUser.role === 4 || $store.state.user.currentUser.role === 5 || $store.state.user.currentUser.role === 11) && (!$store.state.user.kioskUser || !$store.state.user.kioskUser.oid)" class="dropdown-item" href="#" @click.prevent="setupAsKiosk">{{ $t('Setup as new Kiosk device') }}</a>
            <a v-if="$store.state.user.kioskable && ($store.state.user.currentUser.role === 4 || $store.state.user.currentUser.role === 5 || $store.state.user.currentUser.role === 11) && (!$store.state.user.kioskUser || !$store.state.user.kioskUser.oid)" class="dropdown-item" href="#" @click.prevent="setupAsKioskWithExistingUser">{{ $t('Setup as kiosk with existing user') }}</a>
            <hr v-if="$store.state.user.kioskable && ($store.state.user.currentUser.role === 4 || $store.state.user.currentUser.role === 5 || $store.state.user.currentUser.role === 11) && (!$store.state.user.kioskUser || !$store.state.user.kioskUser.oid)"/>
            <template v-for="rs in $store.state.fleet.roomsetList.filter(r => r.organizationLabel === $store.state.fleet.currentOrganization && r.label.charAt(0) !== '_')">                        
								<div class="dropdown-item"  :key="rs.oid" >
									<input type="checkbox" :checked="$store.state.fleet.visibility[rs.oid]" @change="switchDisplay(rs, $event)"  />              
                  <span style="margin-left: 15px">{{ $t('show ') + rs.label }}</span>                
								</div>
					  </template>


<!--
                <div class="dropdown-item" >                
                <b-form-input id="range-1" v-model="roomWidth" type="range" min="1" max="6"></b-form-input>
                <div class="mt-2">{{ roomWidth }} per line</div>                
              </div>
              -->
            <hr  v-if="$store.state.user.kioskUser.oid && ($store.state.user.currentUser.role === 4 || $store.state.user.currentUser.role === 5 || $store.state.user.currentUser.role === 11)"/>
            <a  v-if="$store.state.user.kioskUser.oid && ($store.state.user.currentUser.role === 4 || $store.state.user.currentUser.role === 5 || $store.state.user.currentUser.role === 11)" class="dropdown-item" href="#" @click.prevent="logout">{{ $t('Sign out Kiosk') }}</a>
          </div>
          
        </b-dropdown>
      </div>  
</template>

<script>
import { AuthService } from '@/services/auth.service'

export default {
  name: 'Header',
  components: {
    
  },
  methods: {
    async logout () {
      await AuthService.makeLogoutKiosk()
      if(!this.$store.state.user.currentUser.oid)
        this.$router.push('/app/sessions/signIn')
    },
    async login () {
      // we need to start a modal for login
      this.$store.dispatch('user/needsLogin',true);
    },
    switchDisplay(roomset, ev) {
        this.$store.commit('fleet/SET_ROOMSET_VISIBLE', { roomset, visible: ev.target.checked})        ;
    },
    hideContent () {
      if (this.isShown) this.isShown = false
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown () {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown () {
      this.activeNotifications = false
    },
    toggleSidebar () {      
        
        this.$store.commit('user/SET_KIOSKMODE',!this.$store.state.user.kioskMode)
    },
    hideSidebar () {
      this.$sidebar.displaySidebar(false)
    },
    changePattern() {
       this.$store.commit('user/SET_NEEDS_CHANGEPATTERN',true)
    },
    changePassword() {
       this.$store.commit('user/SET_NEEDS_CHANGEPASSWORD',true)
    },   
    setupAsKioskWithExistingUser() {
      this.$store.dispatch('user/needsKioskLogin',true);
    },
    setupAsKiosk() {
      console.log('setupAsKiosk')
     this.$store.dispatch('user/needsKioskCreatesUser',true);
    }
  },
  data() {
    return {
      
    }
  },  
  computed: {
    roomWidth: {
      get () {
        return this.$store.state.fleet.roomWidth
      },
      set (value) {
        this.$store.commit('fleet/ROOM_WIDTH', parseInt(value))
      }
    },
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      },
      isLogged() {
          return (this.$store.state.user.currentUser.username && this.$store.state.user.currentUser.role > 0)
      },
      showLogin() {        
        return (!this.$store.state.user.currentUser.username || this.$store.state.user.currentUser.role === 0)  && this.$router.currentRoute.path.indexOf('authorize') < 0                
      },
      iconChar() {
        if(this.$store.state.user.currentUser.username && this.$store.state.user.currentUser.role > 0)
          return '&#x1F464;'
        else return '&#128274;'
      },
      navclass() {
        if(!this.$store.state.user.kioskMode && !this.$store.state.user.kioskPage)
          return "administration"
        else return "kiosk"
      },
  },  
  mounted() {   
     if (window.plugins && window.plugins.insomnia && window.plugins.insomnia.keepAwake) {
        console.log('activating keepawake')
        window.plugins.insomnia.keepAwake();
     }
    }
}
</script>

<style lang="scss" scoped>
.menu-text {
    color: #ffffff;
    text-align: center;
    font-weight: 400;
    line-height: 30px;
  }

.header {
  z-index: 1;
  padding: 20px 0;
  position: relative;

  .is-active {
    color: #e01b3c;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  

  .menu {
    font-size: 25px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      padding: 15px;

    }

    &.side {
      font-size: 15px;

      .logout-button {
        cursor: pointer;
      }
    }
  }
}
</style>
