<template>
  <b-modal
      hide-footer
      v-model="showBedExitSettings"
      @hidden="close"
      :title="$t('Edit Room Bed Exit Settings')">
      <div v-if="!isEnabled && hasLicense" class="container h-100">
        <span>
            {{ $t('This feature has been disabled by your administrator.') }}
        </span>
      </div>
      <div v-if="!hasLicense" class="container h-100">
        <span>
            {{ $t('Your current ISA subscription level does not allow you to edit those settings.') }}
            <br/>
            {{ $t('Please contact your administrator.') }}
        </span>
      </div>      
      <div v-if="isEnabled && hasLicense">
        <b-card >

        <validation-observer ref="observer" v-slot="{ handleSubmit }" >          
          <b-form @submit.stop.prevent="handleSubmit(save)">            
          <!-- good here -->
          <b-form-group
            label-cols-sm="3"
            :label="$t('Enable bed exit detection:')"        
            label-align-sm="left"
            label-for="enableBedExit"
            id="kiosk_enablebedexit"
          >


          <b-form-checkbox v-if="!behacked" v-model="benableBedExit"></b-form-checkbox>
          
          <b-form-radio-group v-if="behacked" class="pt-2" 
              :options="translatedEnableBedExitHackedOptions" 
              v-model="enableBedExitHacked"              
              aria-describedby="input-2-live-feedback"
              ></b-form-radio-group>


          </b-form-group>



          <b-form-group
            label-cols-sm="3"
            :label="$t('24/7 Always On (Not recommanded)')"
            label-align-sm="left"
            label-for="enableAlways"
          >
          <b-form-checkbox v-model="enableAlways" id="kiosk_enablealwayson"></b-form-checkbox>
          </b-form-group>     

          <b-collapse  id="collapseAdvanced" class="mt-2" style="margin-top: 5px; margin-bottom: 5px" v-model="enableBedExit">  
           

            

            <validation-provider
              :name="$t('Start time:')"
              :rules="{ required: !enableAlways }"
              v-slot="validationContext"
            >
              <b-form-group
              v-if="!enableAlways"
              label-cols-sm="3"
              :label="$t('Start time:')"        
              label-align-sm="left"
              label-for="timeStart">          
                  <b-form-timepicker id="timepicker-start"                                       
                                      v-model="timeStart" 
                                      size="lg" 
                                      :hour12="false" 
                                      local="fr"
                                      minutes-step=15
                                      aria-describedby="input-timepicker-start"
                                      :state="getValidationState(validationContext)"
                                      >
                  </b-form-timepicker>
                  <b-form-invalid-feedback
                    id="input-timepicker-start"
                  >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>


            <validation-provider
              :name="$t('End time:')"
              :rules="{ required: !enableAlways }"
              v-slot="validationContext"
            >
              <b-form-group
              v-if="!enableAlways"
              label-cols-sm="3"
              :label="$t('End time:')"        
              label-align-sm="left"
              label-for="timeEnd">          
                  <b-form-timepicker id="timepicker-end"                                       
                                      v-model="timeEnd" 
                                      size="lg" 
                                      :hour12="false" 
                                      local="fr"
                                      minutes-step=15
                                      aria-describedby="input-timepicker-end"
                                      :state="getValidationState(validationContext)"
                                      >
                  </b-form-timepicker>
                  <b-form-invalid-feedback
                    id="input-timepicker-end"
                  >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
        
                     
          </b-collapse>
            
          <b-button v-if="readyToSave" class="float-left" @click="close" id="kiosk_cardcancel">
              {{ $t('Cancel')}}
          </b-button>
          <b-button v-if="readyToSave" variant="primary" class="float-right" type="submit" id="kiosk_cardapply">
              {{ $t('Apply')}}
          </b-button>
            
          </b-form>
        </validation-observer>

        
          


      </b-card>
    </div>
  </b-modal>  
</template>

<script>
import moment from 'moment'


export default  {
  components: {    
    
  },  
  data() {
    return {
      showBedExitSettings: false,
      room: null,
      
      enableAlways: false,  
      timeStart: null,
      timeEnd: null,
      readyToSave: true,
      benableBedExit: false,
      enableBedExitHacked: 0,
      enableBedExitHackedOptions: [
        { value: 0, text: this.$t("Disabled") },
        { value: 1, text: this.$t("Bed Exit") },
        { value: 2, text: this.$t("No return to bed after 10min") },      
      ],
      
    }
  },
  computed: {

    translatedEnableBedExitHackedOptions() {
      return this.enableBedExitHackedOptions.map((option) => ({
        value: option.value,
        text: this.$t(option.text),
      }))
    },

    behacked() {
      if( this.$store.state.fleet.currentOrganization == 'acuity'
      || this.$store.state.fleet.currentOrganization.startsWith('intercommunale_des_seniors_des_communes_de_la_haute_')
      || this.$store.state.fleet.currentOrganization == 'ter_heide_8143' )
        return true;
      return false;
    },

    enableBedExit: {
    // getter
    get: function () {
      if(!this.behacked)
      {
        return this.benableBedExit;
      } else {
        return this.enableBedExitHacked > 0; // (1 or 2)
      }
      
    }, 
    set: function(v) {

    }
    
  },
    
    hasLicense() {
      return true;
      /*
      if(!this.room) return false;
      for(let rs of this.$store.state.fleet.roomsetList)
        if(rs.organizationLabel === this.$store.state.fleet.currentOrganization && rs.roomList)
        for(let r of rs.roomList) {
          if(r.oid === this.room.oid) {            
            const allowedLicenses = rs.licensing.activeBedExitAllowed;
            const usedLicenses = rs.roomList.filter(p => p.sensor && p.sensor.settings && p.sensor.settings.enableBedExitDetection).length;
            let result = rs.licensing && (rs.licensing.activeBedExitAllowed < 0 || allowedLicenses > usedLicenses);
            debugger;
            return result
          }
        }
      return false;*/
    },
    isEnabled() {
      if(!this.room) return false;
        for(let rs of this.$store.state.fleet.roomsetList)
        if(rs.organizationLabel === this.$store.state.fleet.currentOrganization && rs.roomList)
        for(let r of rs.roomList) {
          if(r.oid === this.room.oid) {
              return rs.featureBedexitEnabled;
          }
        }
      return false;
    },

    action() {
      return this.$store.state.fleet.watchingImgLoaded == null ? '' : 'Live from ' + this.$store.state.fleet.watchingLabel
    },
    imgDate() {
      let d = moment(this.$store.state.fleet.watchingImgLoadedDate);    
      if(d.isValid())  
        return d.format('hh:mm:ss')
      else return 'loading...'
    },
    
  },
   watch : {
    
    '$store.state.user.showBedExitSettings': function(val) {     
      console.log('yay --> ' + val)            
      this.showBedExitSettings = val
    },
    '$store.state.user.showBedExitSettingsRoom': function(val) {                   
      this.room = val
      if(this.room && this.room.sensor && this.room.sensor.settings) {
        if(!this.behacked) {
          this.benableBedExit = this.room.sensor.settings.enableBedExitDetection || false;
        } else {
          debugger
          this.enableBedExitHacked = 0;
          if(this.room.sensor.settings.enableBedExitDetection)        
            this.enableBedExitHacked = 1;
          if(this.room.sensor.settings.enableBedExitDetectionType == 2)
            this.enableBedExitHacked = 2;
        }

        
        


        let bedExitTimeRanges = this.room.sensor.settings.bedExitTimeRanges;
        if(bedExitTimeRanges && bedExitTimeRanges.length) {
          let tr = bedExitTimeRanges[0];
          console.log(tr);
          if(tr.startHourLocalTime || 
            tr.startMinuteLocalTime ||
            tr.endHourLocalTime ||
            tr.endMinuteLocalTime)
            {
              this.enableAlways = false;
              this.timeStart = `${tr.startHourLocalTime}:${tr.startMinuteLocalTime}`;
              this.timeEnd = `${tr.endHourLocalTime}:${tr.endMinuteLocalTime}`;
            }
            else
            {
              this.enableAlways = true;
            }
        } else
        {
          this.enableAlways = true;
        }
      }
    }
  },    
  methods: {       
    close()  {
      this.$store.commit('user/SHOW_BEDEXITSETTINGS',null)
    },  
    selectedMStart() {
      console.log('selected minutes')
    },
    selectedMEnd() {
console.log('selected minutes')
    },
    formatedTime(e) {
      console.log('formated: ' + e);
    },
    async save() {

      // first check is logged as user and not kiosk
      if(this.$store.state.user.currentUser.role === 0) {
        return await this.$store.dispatch('user/needsLogin', true);
      }

      if(!this.enableBedExit)
        await this.$store.dispatch('fleet/disableBedExit', this.room.oid, this.enableBedExitHacked);
      else
        await this.$store.dispatch('fleet/enableBedExit', { oid: this.room.oid, behack: this.enableBedExitHacked });

      let settings = {
      }
      if(this.enableAlways) 
        settings = {
        bedExitTimeRanges:
            [{
                startHourLocalTime: 0,
                startMinuteLocalTime: 0,
                endHourLocalTime: 0,
                endMinuteLocalTime: 0,
            }]
          };
      else {
        let ss = this.timeStart.split(':');
        let se = this.timeEnd.split(':');
        settings = {
        bedExitTimeRanges: [{
            startHourLocalTime: parseInt(ss[0]),
            startMinuteLocalTime: parseInt(ss[1]),
            endHourLocalTime:  parseInt(se[0]),
            endMinuteLocalTime: parseInt(se[1]),
          }]
        };
      }
      console.log(this.timeStart)
      console.log(this.timeEnd)
      console.log(moment(this.timeEnd))
      console.log(settings)
      await this.$store.dispatch('fleet/setBedExitSettings', { roomOID: this.room.oid, settings});

      this.$store.commit('user/SHOW_BEDEXITSETTINGS',null);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    }, 
  },
    mounted() {
    this.$store.commit('user/SHOW_BEDEXITSETTINGS',null)
  },
  name: "ISARoomBedExitSettings"
}
</script>

<style lang="scss" scoped>
.form-control {
  height: 100% !important ;
}
</style>
