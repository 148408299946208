 <template>
  <div class="footer_wrap">
    <!-- Footer Start  -->
    <div class="flex-grow-1"></div>
    <div class="app-footer">
      <!--
      <div class="row">        
        
        <div class="col-md-9">
          <p><strong>Gull - Vuejs Admin Dashboard Template</strong></p>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero
            quis beatae officia saepe perferendis voluptatum minima eveniet
            voluptates dolorum, temporibus nisi maxime nesciunt totam
            repudiandae commodi sequi dolor quibusdam sunt.
          </p>
        </div>
      </div>
      -->
      <div
        class="d-flex flex-column flex-sm-row align-items-center"
      >
        <div class="d-flex align-items-center">
          <img class="logo" :src="IMGService.logoMintt" alt="" style="width: 200px" v-touch:longtap="hardRefresh"/>
          <div>
            <div>
              <p class="m-2">&copy; {{new Date().getFullYear()}} MintT SA</p>
              <p class="m-2">{{ $t('All rights reserved') }}</p>
            </div>
          </div>
          
          

        </div>
                  <span
            style="margin-left: auto;"
            href="https://themeforest.net/item/gull-vuejs-admin-dashboard-template/24497287?ref=ui-lib"
            target="_blank"
            >{{ MYISAVERSION }}</span> 
      </div>
      <!-- fotter end -->
    </div>
  </div>
</template> 
<script>
import { IMGService } from '@/services/img.service'
import { VERSIONDATE, MYISAVERSION } from '@/version'

export default {
  data() {
    return {
      IMGService,
      VERSIONDATE,
      MYISAVERSION,
    };
  },
  methods: {
    hardRefresh() {
      this.$store.dispatch("user/showAbout", true);       
    },
  }
};
</script>


<style lang="scss" scoped>
.app-footer {
  background: white
}
</style> 