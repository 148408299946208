<template>  
    <b-row v-if="$store.state.user.currentUser  && $store.state.user.currentUser.role > 0 && $store.state.fleet.organizationList && $store.state.fleet.organizationList.length > 1">      
      <div class="col-12 col-sm-12">
          <v-select id="selector_currentOrganization" v-model="currentOrganization" :options="$store.state.fleet.organizationList" />
      </div>
    </b-row>
</template>

<script>

export default {
  name: "OrganizationSelector",  
  data() {
    return {
      
    }    
  },
  computed: {
    currentOrganization: {
      get: function () {
        return this.$store.state.fleet.currentOrganization
      },
    // setter
      set: function (newValue) {        
        this.$store.dispatch("fleet/setCurrentOrganization", newValue );
      }   
    }  
  },
  components: {
    
  },
  model: {
      event: 'change'
  },
  methods: {        
    async changeOrg(org) {        
        console.log('changing org ' + org)        
        await this.$store.dispatch('fleet/setCurrentOrganization',this.currentOrganization)        
        this.$emit('change', org)
        await this.$store.dispatch('fleet/loadOrganizationInfo');
        this.$store.dispatch('fleet/fillReportReload');
    }
  },
  mounted() {      
    
  },
  created() {
    
  }
};
</script>

<style lang="scss" scoped>

</style>
